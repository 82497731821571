<template>
	<div class="MapFilters">
		<v-card
		elevation="2"
		class="pa-2"
		v-if="0 !== legendsDisplayed.length"
		>
			<v-card
			v-for="layer in legendsDisplayed"
			:key="layer.id"
			elevation="0"
			class="white pb-0 no-scroll"
			>
				<v-card-title class="pt-0 pb-0">
					<h3>{{ $cap(layer.name) }}</h3>
				</v-card-title>

				<v-card-text class="pb-2">
					<v-checkbox
					class="mt-0 pt-0 miniCheckbox"
					color="darkgrey"
					off-icon="mdi-checkbox-blank-outline"
					on-icon="mdi-checkbox-marked"
					dense
					v-for="layerLegends in layer.legends"
					:key="layerLegends.id"
					v-model="layerLegends.selected"
					>
						<template v-slot:label>
							<div class="labelBox">
								<div v-if="layer.geomType == getMapLayerConstTypes().POINT && layer.symbolType != getSymbolConstTypes().MAPLAYER_SYMBOL_TYPE.DEFAULT">
									<img
									:src="imagePaths[getLegendIconIndex(layer, layerLegends)]"
									:height="layerLegends.thickness+'px'"
									/>
								</div>

								<div
								v-else
								:style="{
									background: layerLegends.color,
									width: getAjustedLegendThickness(layer.id, layer.geomType, layerLegends.thickness) +'px',
									height: getAjustedLegendThickness(layer.id, layer.geomType, layerLegends.thickness) +'px',
								}"
								class="mr-2"
								/>

								<span> {{ layerLegends.label }}</span>
							</div>
						</template>
					</v-checkbox>
				</v-card-text>
			</v-card>
		</v-card>
	</div>
</template>

<script>
import MAPLAYER_TYPE from "../../utils/map/maplayertype.const";
import SYMBOL_TYPE from "../../utils/map/symboltype.const";
import ImageUtils from "../../utils/image";

export default {
	name: "AuscultationMapFilters",
	props: {
		layersLegend: {
			required: true,
			type: Array
		},
		selectedLayers: {
			required: true,
			type: Array
		}
	},
	data: () => ({
		filters: [],
		legendsWithSize: {},
		id: "placeholder",
		imagePaths: {}
	}),
	computed: {
		legendsDisplayed(){
			return this.layersLegend.filter(
				ll => this.selectedLayers.filter(sl => sl == ll.id).length > 0
			);
		}
	},
	watch: {
		layersLegend: {
			handler: function(newVal){
				if(this.filters.length === 0){
					this.filters = [...newVal];
				}
				else {
					let newFilters = [];
					newVal.forEach(el => {
						let filtersFilteredByEl = this.filters.filter(filter => {
							filter.id === el.id;
						});
						if(filtersFilteredByEl.length === 0) newFilters.push(el);
						else newFilters.push(filtersFilteredByEl[0]);
					});
					this.filters = [...newFilters];
				}
				this.filters.forEach((filter, indexFilter) => {
					filter.legends.forEach((legend, indexLegend) => {
						this.$set(
							this.filters[indexFilter].legends[indexLegend],
							"selected",
							true
						);

						let symbolType = filter.symbolType;
						if(MAPLAYER_TYPE.POINT == filter.geomType && SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.DEFAULT != symbolType){
							let index = this.getLegendIconIndex(filter, legend);
							let image = this.getLegendIconData(filter, legend);
							if(null != image){
								if(SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.VERTICAL_SIGNAL === symbolType || 
									SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.PERSONAL_ICON === symbolType){
									this.addLegendIcon(image, index);
								}
								else {
									ImageUtils.replaceGrayscaleByColor(image, legend.color, index, this.addLegendIcon);
								}
							}
						}
					});
				});
				// Calculate if this layer use size to present marker
				newVal.forEach(layer => {
					this.legendsWithSize[layer.id] = false;
					for(let i = 0; i < layer.legends.length - 1; i++){
						if(layer.legends[i].thickness != layer.legends[i + 1].thickness){
							this.legendsWithSize[layer.id] = true;
							break;
						}
					}
				});
			},
			deep: false
		},
		filters: {
			handler: function(newVal){
				this.setFilters(newVal);
			},
			deep: true
		}
	},
	methods: {
		getMapLayerConstTypes(){
			return MAPLAYER_TYPE;
		},
		getSymbolConstTypes(){
			return SYMBOL_TYPE;
		},
		getLegendIconIndex(layer, legend){
			if(layer.geomType == MAPLAYER_TYPE.POINT){
				let symbolType = layer.symbolType;
				let symbolInfo = legend.symbolInfo ? legend.symbolInfo : layer.symbolInfo;
				let color = legend.color;
				switch (layer.symbolType){
					case SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.DIRECTION:
						return legend.id + "_" + symbolType + "_*_" + color;
					case SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.PR:
						return legend.id + "_" + symbolType + "_*_" + color;
					case SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.FONTAWESOME_BRANDS_ICON:
					case SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.FONTAWESOME_REGULAR_ICON:
					case SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.FONTAWESOME_SOLID_ICON:
						return legend.id + "_" + symbolType + symbolInfo + color;
					case SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.VERTICAL_SIGNAL:
						return legend.id + "_" + symbolType + symbolInfo;
					case SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.PERSONAL_ICON:
						return legend.id + "_" + symbolType + symbolInfo;
					default:
						return null;
				}
			}
		},
		getLegendIconData(layer, legend){
			let symbolType = layer.symbolType;
			let symbolInfo = legend.symbolInfo ? legend.symbolInfo : layer.symbolInfo;
			switch (symbolType){
				case SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.DIRECTION:
					return require("@/assets/images/symbols/direction/64x64.png");
				case SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.PR:
					return require("@/assets/images/symbols/pr/64x64.png");
				case SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.FONTAWESOME_BRANDS_ICON:
					return require("@/assets/images/symbols/fontawesome/64x64/brands/" + symbolInfo + ".png");
				case SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.FONTAWESOME_REGULAR_ICON:
					return require("@/assets/images/symbols/fontawesome/64x64/regular/" + symbolInfo + ".png");
				case SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.FONTAWESOME_SOLID_ICON:
					return require("@/assets/images/symbols/fontawesome/64x64/solid/" + symbolInfo + ".png");
				case SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.VERTICAL_SIGNAL:
					return require("@/assets/images/symbols/verticalsignal/64x64/" + symbolInfo + ".png");
				case SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.PERSONAL_ICON:
					return require("@/assets/images/symbols/personal/64x64/" + symbolInfo + ".png");
				default:
					return null;
			}
		},
		addLegendIcon(imagePath, imageName){
			if(!this.imagePaths.hasOwnProperty(imageName)){
				this.$set(
					this.imagePaths,
					imageName,
					imagePath
				);				
			}
		},
		getAjustedLegendThickness(layerId, geomType, thickness){
			if(this.legendsWithSize[layerId]){
				return (geomType == MAPLAYER_TYPE.POINT) ? thickness / 1.1 : thickness * 1.1;
			}
			else {
				return 10;
			}
		},
		resetFilter(legendId){
			let layerToReset = this.layersLegend.filter(ll => ll.id == legendId)[0];
			layerToReset.legends = layerToReset.legends.map(leg => {
				leg.selected = true;
				return leg;
			});
		},
		setFilters(newVal){
			this.$emit("filterChanged", newVal);
		},
		orderByFilters(){
			this.legendsDisplayed.forEach(layer => {
				if(layer.legends[0].position === null){
					layer.legends.sort((a, b) => a.label.localeCompare(b.label));
				}
			});
		}
	},
	mounted(){
		this.setFilters(this.filters);
		this.orderByFilters();
	}
};
</script>

<style lang="scss">
.MapFilters {
  font-family: Roboto, sans-serif;
  position: absolute !important;
  top: 10px !important;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;

  .v-card {
    overflow-y: auto;
    max-height: 44vh;
  }

  .v-input {
    &__slot {
      padding-bottom: 0 !important;
    }
  }

  .miniCheckbox i {
    transform: scale(0.8) !important;
  }

  .v-card__title {
    padding-bottom: 5px;
    h3 {
      font-size: 20px;
      justify-content: center;
      width: 100%;
    }
  }

  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: #ccc;
  }

  ::-webkit-scrollbar-thumb {
    background: #290703ee;
    -webkit-border-radius: 1ex;
    border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  .v-messages {
    display: none !important;
  }

  .labelBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      font-weight: normal;
      text-transform: none;
      font-size: 12px;
      letter-spacing: 0.4px;
      color: rgba(0, 0, 0, 0.6);
    }
    div {
      border-radius: 12px;
      margin-right: 2px;
    }
  }
  .reset_button_container {
    padding-top: 5px;
    display: flex;
    justify-content: center;
  }
}
.no-scroll {
	overflow-y: visible !important;
}
</style>
