//ATTENTION : Chart's types must be synchronized with ChartTypeEnum of back
export default Object.freeze({  
    ColumnNBar: { 
      name: 'ColumnNBar',
      subTypes: {
        ColumnNBarSimple: {
          name: 'ColumnNBarSimple'
        },
        ColumnNBarStacked: {
          name: 'ColumnNBarStacked'
        }
      }
    },
    Pie: {
      name: 'Pie',
      subTypes: {
        PieSimple: {
          name: 'PieSimple'
        }
      }
    }
  });
  