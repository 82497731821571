<template>
	<div
	id="auscultation-campaign-map"
	class="tw-p-[24px] tw-flex tw-flex-col tw-gap-[5px]"
	>
		<HeaderSlot
		:title="$cap(auscultationCampaign.name)"
		sub-title="Carte"
		:toBackButton="{ name: 'project', params: { id: workspaceId } }"
		:isGuest="isGuest"
		:isBackButtonHidden="isGuest"
		>
			<ButtonSlot
			v-if="
				false === isGuest &&
					(auscultationCampaign.status === 'finishing' ||
						auscultationCampaign.status === 'finished' ||
						auscultationCampaign.status === 'archived') &&
					false === $store.state.user.current.isInternal
			"
			@click="openGenerateLinkModale()"
			_icon="mdi-link-variant-plus"
			:_res="1550"
			>
				Générer un lien public
			</ButtonSlot>

			<EngineerQuestion
			v-if="!isGuest && false === $store.state.user.current.isInternal"
			:_res="1300"
			/>

			<ButtonSlot
			:_to="{
				name: dashboardComponentName,
				params: { id: auscultationCampaign.id }
			}"
			_icon="mdi-file-document-outline"
			:_res="1300"
			>
				Tableau de bord
			</ButtonSlot>
		</HeaderSlot>

		<div
		ref="printMe"
		class="loading_container"
		v-if="mapDisplayedBool === false"
		>
			<span>Vos résultats seront bientôt disponibles</span>
		</div>

		<div
		class="tw-w-full tw-grow"
		v-if="!loading && mapDisplayedBool === true"
		outlined
		>
			<AuscultationMap
			:layersList="layersList"
			:filters="filters"
			:selectedLayers="selectedLayers"
			:AUPrsLayerInfo="AUPrsLayerInfo"
			:RNPrsLayerInfo="RNPrsLayerInfo"
			:RDPrsLayerInfo="RDPrsLayerInfo"
			:filterValues="filterValues"
			:changeFilter="changeFilter"
			@closeMenu="closeMenu"
			>
				<AuscultationMenu
				ref="menu"
				v-if="layersList.length"
				:layersList="layersList"
				@layerSelected="layerSelected"
				@reloadLayers="reloadLayers"
				/>

				<AuscultationMapFilters
				:layersLegend="layersLegend"
				:selectedLayers="selectedLayers"
				@filterChanged="changeFilter"
				/>
			</AuscultationMap>
		</div>

		<Modale
		v-if="isGenerateLinkModaleOpen"
		title="Partager la campagne"
		@close="closeGenerateLinkModale"
		@validate="closeGenerateLinkModale"
		>
			<div class="generateLinkInput">
				<div
				class="justify-center mt-4"
				style="width: 100%"
				>
					<v-row class="pr-4 pl-4">
						<v-text-field
						dense
						ref="generatedLink"
						outlined
						disabled
						:value="url"
						:loading="loadUrlGuest"
						class="mr-4"
						/>

						<MediumButtonSlot @click="copyTextToClipboard()">
							Copier le lien
						</MediumButtonSlot>
					</v-row>
				</div>
			</div>

			<template slot="actions">
				<LowButtonSlot @click="closeGenerateLinkModale()">
					Fermer
				</LowButtonSlot>
			</template>
		</Modale>
	</div>
</template>

<script>
import AuscultationMap from "../../components/auscultation/map/Auscultation.map.vue";
import AuscultationMenu from "../../components/auscultation/map/Auscultation.map.menu.vue";
import AuscultationMapFilters from "../../components/map/AuscultationMap.filtre.vue";
import MAPLAYER_STATUS from "../../utils/map/maplayerstatus.const";
import FILTERS from "../../utils/map/filters.const";
import auth from "../../service/auth";
import EngineerQuestion from "../../components/popup/EngineerQuestion";

export default {
	name: "AuscultationCampaignMap",
	components: {
		AuscultationMap,
		AuscultationMenu,
		AuscultationMapFilters,
		EngineerQuestion
	},
	data(){
		return {
			isGuest: false,
			dashboardComponentName: "",
			auscultationCampaign: {name: "", id: "", status: ""},
			layersList: [],
			selectedLayers: [],
			filters: [],
			loading: true,
			workspaceId: "",
			layersLegend: [],
			AUPrsLayerInfo: null,
			RNPrsLayerInfo: null,
			RDPrsLayerInfo: null,
			filterValues: [],
			mapDisplayedBool: false,
			isGenerateLinkModaleOpen: false,
			url: "",
			loadUrlGuest: true,
		};
	},
	methods: {
		reloadLayers(data){
			this.filterValues = data;
		},
		openGenerateLinkModale(){
			this.isGenerateLinkModaleOpen = true;
			this.generateShareLink();
		},
		generateShareLink(){
			this.$api.campaigns
			.inviteGuestUser(this.$route.params.id)
			.then(guestUser => {
				this.url =
            window.location.origin + "/share-link?token=" + guestUser.token;
				this.loadUrlGuest = false;
			});
		},
		fallbackCopyTextToClipboard(){
			this.$refs.generatedLink.focus();
			this.$refs.generatedLink.select();

			try {
				document.execCommand("copy");
			}
			catch (err){
				console.error("unable to copy", err);
			}
		},
		copyTextToClipboard(){
			if(!navigator.clipboard){
				this.fallbackCopyTextToClipboard();
				return;
			}
			navigator.clipboard.writeText(this.url);
			this.$toast("success", "Lien copié dans le presse papier !");
		},
		closeGenerateLinkModale(){
			this.isGenerateLinkModaleOpen = false;
			this.loadUrlGuest = true;
		},
		changeFilter(newVal){
			let filterChanged = newVal.map(layerLegend => {
				let filters = layerLegend.legends
				.filter(el => el.selected)
				.map(legend => {
					if(legend.selected){
						let layerColumn = layerLegend.column;
						if(legend.id == FILTERS.ALL_FEATURE_FILTER_ID){
							return [
								">=", "gid", 0
							];
						}
						if(true === legend["isNull"]){
							return ["!", ["has", layerColumn]];
						}

						if(legend.valueExact && legend.valueExact.length > 0){
							let allFilters = ["any"];
							legend.valueExact.forEach(val => {
								allFilters.push([
									"==",
									["to-string", ["get", layerColumn]],
									["to-string", `${val}`]
								]);
							});
							return [
								"all", ["has", layerColumn], [...allFilters]
							];
						}
						else {
							let filter = ["all"];
							filter.push(["has", layerColumn]);
							filter.push([
								legend.excludeMin ? ">" : ">=",
								["get", layerColumn],
								legend.min
							]);
							filter.push([
								legend.excludeMax ? "<" : "<=",
								["get", layerColumn],
								legend.max
							]);
							return filter;
						}
					}
				});
				return {id: layerLegend.id, filters: filters};
			});
			this.filters = filterChanged;
		},
		closeMenu(isOpen){
			this.$refs.menu.changeExpansionPanel(isOpen);
		},

		getLayersList(campaignId){
			this.$api.mapLayers.findByCampaign(campaignId).then(async layers => {
				this.layersList = layers;
				this.loading = false;
				this.layersLegend = await this.getLayersLegends(this.layersList);
			});
		},
		loadImage(directionImage, imageName){
			let that = this;
			that.map.loadImage(directionImage, 
				function(error, image){
					if(error) throw error;
					that.map.addImage(imageName, image);
				}
			);
		},
		async getLayersLegends(layers){
			let layersLegends = [];
			let getLayerLegend = async layer => {
				if(layer.status === MAPLAYER_STATUS.GENERATION_FINISHED){
					const legends = await this.$api.mapLayers.getLegends(layer.id);
					return {
						id: layer.id + "_Id",
						name: layer.name,
						legends,
						column: layer.layerColumn,
						geomType: layer.geomType,
						symbolType: layer.symbolType,
						symbolInfo: layer.symbolInfo
					};
				}
				else {
					return null;
				}
			};
			for(let i = 0; i < layers.length; i++){
				let layer = layers[i];
				if(!layer.isContainer){
					let legendLayer = await getLayerLegend(layer);
					if(null !== legendLayer){
						layersLegends.push(legendLayer);
					}
				}
				else {
					let childLayersLegends = await this.getLayersLegends(layer.children);
					if(childLayersLegends.length > 0){
						layersLegends.push(...childLayersLegends);
					}
				}
			}
			return layersLegends;
		},
		layerSelected(selectedLayers){
			this.selectedLayers = selectedLayers;
		},
		async isMapDisplayed(){
			const guestToken = localStorage.getItem("guest_token");
			const user = auth.decodeToken();

			if(undefined === guestToken) return setTimeout(this.isMapDisplayed, 200);

			this.mapDisplayedBool = null !== user
				? (user.job === "study_manager" &&
            [
            	"processing",
            	"engineering",
            	"finishing",
            	"finished",
            	"archived"
            ].indexOf(this.auscultationCampaign.status) !== -1) ||
            (user.job === "engineer" &&
              [
              	"engineering", "finishing", "finished", "archived"
              ].indexOf(
              	this.auscultationCampaign.status
              ) !== -1) ||
            (user.job === "commercial" &&
              [
              	"finishing", "finished", "archived"
              ].indexOf(
              	this.auscultationCampaign.status
              ) !== -1) ||
            (user.job === null &&
              [
              	"finishing", "finished", "archived"
              ].indexOf(
              	this.auscultationCampaign.status
              ) !== -1)
				: null !== guestToken &&
            [
            	"finishing", "finished", "archived"
            ].indexOf(
            	this.auscultationCampaign.status
            ) !== -1;
		}
	},
	mounted(){
		if(!auth.isLogged()) this.isGuest = auth.isLoggedGuest();
		this.dashboardComponentName =
      this.isGuest && !auth.isLogged()
      	? "auscultation-campaign-dashboard-guest"
      	: "auscultation-campaign-dashboard";

		this.$api.auscultationCampaigns
		.findById(this.$route.params.id)
		.then(async(campaign) => {
			this.auscultationCampaign = campaign;
			this.workspaceId = campaign.workspace.id;
			await this.isMapDisplayed();
			if(this.mapDisplayedBool) this.getLayersList(campaign.id);
		})
		.catch(() => {
			this.loading = false;
		});

		this.$api.mapLayers.getCommonPRsLayer("AU PR").then(layer => {
			this.AUPrsLayerInfo = layer;
		});
		this.$api.mapLayers.getCommonPRsLayer("RN PR").then(layer => {
			this.RNPrsLayerInfo = layer;
		});
		this.$api.mapLayers.getCommonPRsLayer("RD PR").then(layer => {
			this.RDPrsLayerInfo = layer;
		});
	}
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";
#auscultation-campaign-map {
  padding: 15px 15px 0 15px;
  height: 100%;
}
.map_container {
  border-radius: 4px !important;
  overflow: hidden;
  position: relative;
  margin: 0 8px 0 8px;
  height: calc(100vh - 136px);
}
.loading_container {
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
