<template>
	<div
	class="tw-h-full tw-w-[50%] tw-right-full tw-bg-[#f1f1f1] tw-flex tw-items-center"
	@mousewheel.prevent="wheelEvent"
	>
		<div class="tw-w-full tw-relative tw-h-full tw-flex">
			<WaitingSlot
			class="tw-w-full tw-h-full"
			v-if="currentImage === false"
			/>

			<div 
			v-else
			class="tw-w-full tw-h-full tw-pt-[24px] tw-pb-[24px] tw-center tw-gap-[10px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-[5px]"
			>
				<img
				:src="currentImage"
				@click="isBackImage && !isFront ? isFront = ! isFront : isBackImage ? dialog = true : dialog = true"
				class="tw-transition-all img"
				:class="isBackImage && isFront ? 'tw-w-full' : isBackImage ? 'tw-w-[50%]' : 'tw-w-full'"
				/>

				<img
				v-if="backPhotosGeoJson !== null "
				:src="currentBackImage"
				@click="isFront ? isFront = !isFront : dialog = true"
				class="tw-transition-all img"
				:class="isFront ? 'tw-w-[50%]' : 'tw-w-full'"
				/>
			</div>

			<v-icon
			class="tw-absolute tw-top-[50%] tw-right-[15px] tw-text-[35px] tw-text-[white] tw-bg-[rgba(0,0,0,0.35)] tw-rounded-full"
			@click="queueInc"
			>
				mdi-chevron-right
			</v-icon>

			<v-icon
			class="tw-absolute tw-top-[50%] tw-left-[15px] tw-text-[35px] tw-text-[white] tw-bg-[rgba(0,0,0,0.35)] tw-rounded-full"
			@click="queueDec"
			>
				mdi-chevron-left
			</v-icon>
		</div>

		<VDialog
		v-model="dialog"
		width="80rem"
		height="80rem"
		>
			<div class="tw-w-full tw-h-full tw-p-[10px] tw-bg-[white] tw-flex tw-flex-col tw-items-end tw-gap-[10px]">
				<img
				:src="isFront ? currentImage : currentBackImage"
				class="tw-w-full tw-h-full"
				/>

				<ButtonSlot
				_theme="light-gray"
				@click="dialog = false"
				>
					Fermer
				</ButtonSlot>
			</div>
		</VDialog>
	</div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
export default {
	name: "AuscultationDiaporamaMap",
	props: {
		map: {
			type: Object
		},
		photosGeoJson: {
			type: Object
		},
		isOpen: {
			type: Boolean
		},
		indexPopup: {
			type: Number
		},
		backPhotosGeoJson: {
			type: Object
		},
	},

	data(){
		return {
			avatar: null,
			markers: [],
			
			queue: [],
			backQueue: [],
			loadedImage: {},
			backLoadedImage: {},
			index: false,

			dialog: false,
			isBackImage: false,
			isFront: true
		};
	},

	computed: {
		currentImage(){
			return this.loadedImage[this.queue[10]] || false;
		},

		currentBackImage(){
			return this.backLoadedImage[this.queue[10]] || false;
		}
	},

	watch: {
		isOpen(){
			if(this.isOpen === true){
				if(this.backLoadedImage !== null){
					this.isBackImage = true;
				}
				this.index = this.indexPopup;
				this.initQueue();
				this.moveAvatar();
			}
			else if(this.isOpen === false){
				this.index = false;
				this.queue = [];
				this.backQueue = [];
				this.loadedImage = {};
				this.backLoadedImage = {};
				this.clearDecorations();
			}
		},
		indexPopup(){
			if(this.isOpen === true){
				this.index = this.indexPopup;
				this.initQueue();
				this.moveAvatar();
			}
			else if(this.isOpen === false){
				this.index = false;
				this.queue = [];
				this.backQueue = [];
				this.loadedImage = {};
				this.backLoadedImage = {};
				this.clearDecorations();
			}
		}
	},
	methods: {
		moveAvatar(){
			let coords = this.photosGeoJson.features[this.index % this.photosGeoJson.features.length].geometry.coordinates; 
			// Déplacement du camion en fonction des coordonnées de l'image
			if(this.avatar){
				this.avatar.remove();
				this.avatar = null;
			}
			const icon = document.createElement("i");
			icon.className = "large mdi mdi-truck avatar";
			icon.style.fontSize = "xx-large";
			// Add markers to the map.
			this.avatar = new mapboxgl.Marker(icon).setLngLat(coords).addTo(this.map);
			this.$emit("updateModal", this.photosGeoJson.features[this.index]);
		},

		clearDecorations(){
			for(const marker of this.markers){
				marker.remove();
			}
			this.markers = [];
			if(this.avatar) this.avatar.remove();
		},

		initQueue(){
			let queue = [];
			let backQueue = [];
			let images = []; 
			for(let index = 0; index < 21; index++){
				let i = (this.index - 2 + index) % this.photosGeoJson.features.length;
				queue.push(i);
				if(this.backPhotosGeoJson !== null) backQueue.push(i);
				images.push((async() => {
					this.loadedImage[i] = await this.loadImage(i);
					this.loadedImage = {...this.loadedImage};
					if(this.backPhotosGeoJson !== null){
						this.backLoadedImage[i] = await this.loadBackImage(i);
						this.backLoadedImage = {...this.backLoadedImage};
					}
				})());
			}
			this.queue = queue;
			if(this.backPhotosGeoJson !== null) this.backQueue = backQueue;
		},

		async queueInc(){
			this.index++;
			let index = (this.index + 10) % this.photosGeoJson.features.length;
			let shiftedIndex = this.queue.shift();
			this.queue.push(index);
			this.moveAvatar();
			this.$forceUpdate();
			if(this.queue.indexOf(shiftedIndex) === -1) delete this.loadedImage[shiftedIndex];
			this.loadedImage[index] = await this.loadImage(index);
			this.loadedImage = {...this.loadedImage};

			if(this.backPhotosGeoJson !== null){
				this.backQueue.push(index);
				if(this.backQueue.indexOf(shiftedIndex) === -1) delete this.backLoadedImage[shiftedIndex];
				this.backLoadedImage[index] = await this.loadBackImage(index);
				this.backLoadedImage = {...this.backLoadedImage};
			}
		},

		async queueDec(){
			this.index--;
			let index = (this.index - 10) % this.photosGeoJson.features.length;
			let popedIndex = this.queue.pop();
			this.backQueue.unshift(index);
			this.moveAvatar();
			this.$forceUpdate();
			if(this.queue.indexOf(popedIndex) === -1) delete this.loadedImage[popedIndex];
			this.loadedImage[index] = await this.loadImage(index);
			this.loadedImage = {...this.loadedImage};
			if(this.backPhotosGeoJson !== null){
				this.queue.unshift(index);
				if(this.backQueue.indexOf(popedIndex) === -1) delete this.backLoadedImage[popedIndex];
				this.backLoadedImage[index] = await this.loadBackImage(index);
				this.backLoadedImage = {...this.backLoadedImage};
			}
		},

		async loadImage(index){
			let {data} = await this.$axios.get(
				"files/" + this.photosGeoJson.features[index].properties.path,
				{
					responseType: "blob",
					showLoader: false
				}
			);
			return URL.createObjectURL(new Blob([data]));
		},

		async loadBackImage(index){
			let {data} = await this.$axios.get(
				"files/" + this.backPhotosGeoJson.features[index].properties.path,
				{
					responseType: "blob",
					showLoader: false
				}
			);
			return URL.createObjectURL(new Blob([data]));
		},
		wheelEvent(event){
			if(event.deltaY < 0){
				this.queueInc();
			}
			else if(event.deltaY > 0){
				this.queueDec();
			}
		}
	}
};
</script>

<style lang="scss">
@import "../../../assets/scss/_variables.scss";
.diaporama_map {
  background-color: #f1f1f1;
  height: 100%;
  display: flex;
  align-items: center;
}
.avatar {
  color: $brown;
}
.mdi-circle {
  color: #412420 !important;
}
.v-btn--active {
  color: #412420 !important;
  background-color: rgba(65, 36, 36, 0.3) !important;
}
.v-window__container{
	transition-duration: 0;
	display: inherit !important;
}
.img {
	aspect-ratio: 16/9;
}
</style>
