import Vue from "vue";
import mapboxgl from "mapbox-gl";

const generatePopup = function(
	lng,
	lat,
	itemData,
	map,
	component,
	onClose = () => {}
){
	// Check for existing popups and remove them if any
	let existingPopUps = document.querySelectorAll(".mapboxgl-popup");
	if(existingPopUps[0]){
		for(
			let currentPopup = 0;
			currentPopup < existingPopUps.length;
			currentPopup++
		){
			existingPopUps[currentPopup].remove();
		}
	}

	const MapPopupClass = Vue.extend(component);
	const walkPopup = new mapboxgl.Popup({
		closeOnClick: true,
		closeButton: false,
	})
	.setLngLat([lng, lat])
	.setHTML("<div id='vue-popup-content'></div>")
	.addTo(map);

	const popupInstance = new MapPopupClass({
		propsData: {
			features: itemData,
			closeFunc: () => {
				walkPopup.remove();
			},
		},
	});

	walkPopup.on("close", () => {
		onClose();
	});

	popupInstance.$mount("#vue-popup-content");
	walkPopup._update();

	return popupInstance;
};

export default {generatePopup};
