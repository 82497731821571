<template>
	<div class="MapPopup">
		<div class="MapPopup__textBox">
			<div class="MapPopup__roadBox">
				<div
				class="road_container"
				v-for="(column, name) in globalFeatures.roadFeatures"
				:key="name"
				>
					<div class="road_label">
						{{ column["lab"] }}
					</div>

					<div class="road_value">
						{{ column["val"] }}
					</div>
				</div>
			</div>

			<div class="MapPopup__otherBox">
				<p
				v-for="(column, name) in globalFeatures.standardFeatures"
				:key="name"
				>
					<span>{{ column["lab"] }}&nbsp;:&nbsp;</span>

					<span>{{ column["val"] }}</span>
				</p>
			</div>

			<div class="MapPopup__itemBox">
				<p
				v-for="(column, name) in infoFeatures"
				:key="name + 'a'"
				>
					<span>{{ column["lab"] }}&nbsp;:&nbsp;</span>

					<span>{{ typeof (column["val"]) == 'number' ? Math.round(column["val"] * 1000) / 1000 : column["val"] }}</span>
				</p>
			</div>
		</div>

		<div class="d-flex justify-center">
			<medium-button-slot @click="closeFunc">
				Fermer
			</medium-button-slot>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import COLUMN_NAME_CONSTANT from "../../utils/map/columnname.const";

export default Vue.extend({
	props: {
		features: {
			required: true,
			type: Object
		},
		closeFunc: {
			required: true,
			type: Function
		}
	},
	computed: {
		globalFeatures(){
			let standardFeatures = {};
			let roadFeatures = {};
			for(var prop in this.features){
				if(COLUMN_NAME_CONSTANT.standardColumns().includes(prop)){
					let allSettings = this.features[prop];
					if(prop === "road"){
						roadFeatures[prop] = allSettings;
					}
					else {
						standardFeatures[prop] = allSettings;
					}
				}
			}
			return {standardFeatures, roadFeatures};
		},
		infoFeatures(){
			let features = {};
			for(var prop in this.features){
				if(!COLUMN_NAME_CONSTANT.standardNames().includes(prop)){
					try {
						let allSettings = this.features[prop];
						if(!Object.prototype.hasOwnProperty.call(allSettings, "lab")){
							features[prop] = {lab: prop, val: this.features[prop]};
						}
						else features[prop] = allSettings;
					}
					catch (e){
						features[prop] = {lab: prop, val: this.features[prop]};
					}
				}
			}
			return features;
		}
	}
});
</script>

<style lang="scss">
.popup-close-button {
  position: absolute !important;
  top: 3px !important;
  right: 0px !important;
  color: #000 !important;
  border: 0 important;
  border-radius: 0 3px 0 0 important;
  cursor: pointer;
  font-size: x-large;
  &:hover {
    color: red !important;
  }
}
.mapboxgl-popup-content {
  background: #ffffff;
  color: #000;
  border-radius: 10px;
  top: 0px;
  padding: 10px;
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  .MapPopup {
    font: 12px/20px Helvetica Neue,Arial,Helvetica,sans-serif;
    width: 220px;
    max-height: 400px;
    overflow-y: auto;
    padding: 8px;

    &__roadBox {
      min-height: 22px;
      margin-bottom: 5px;
      .road_container {
        margin: auto;
        width: auto;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        font-size: 13px;
        align-items: center;
      }
      .road_label {
        text-transform: uppercase;
        padding: 5px;
        border-radius: 20px;
        background-color: #e43d35;
        color: white;
        font-weight: bold;
        width: auto;
      }
      .road_value {
        font-weight: bold;
        text-align: right;
        margin-left: 10px;
        flex: 1;
      }
    }
    &__otherBox {
      border-bottom: 1px solid rgba(68, 68, 68, 0.562);
      p {
        padding-top: 4px;
        margin: auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
      }
      span:nth-child(1) {
        text-transform: uppercase;
        border-radius: 20px;
        font-weight: bold;
      }
      span:nth-child(2) {
        font-weight: bold;
      }
    }
    &__itemBox {
      padding-top: 10px;
      p {
        width: 100%;

        margin-bottom: 5px;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
      }

      span:nth-child(1) {
        color: #444;
        font-weight: bold;
      }

      span:nth-child(2) {
        font-weight: bold;
      }
    }
    &__btnBox {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }
  }

  .MediumButtonSlot {
    font-family: "Roboto";
  }
}
</style>
