export default Object.freeze({
  ROAD_NAME: "road",
  DIRECTION_NAME: "dir",
  MUNICIPALITY_NAME: "municipality",
  TYPE_NAME: "type",
  COLOR_NAME: "color",
  EXTENSION_NAME: "ext",
  PRD_NAME: "prd",
  ABD_NAME: "abd",
  PRF_NAME: "prf",
  ABF_NAME: "abf",
  CUMULD_NAME: "cumuld",
  CUMULF_NAME: "cumulf",
  GEOM_NAME: "geom",
  standardNames: function() {
    return [
      this.ROAD_NAME,
      this.DIRECTION_NAME,
      this.MUNICIPALITY_NAME,
      this.TYPE_NAME,
      this.COLOR_NAME,
      this.EXTENSION_NAME,
      this.PRD_NAME,
      this.ABD_NAME,
      this.PRF_NAME,
      this.ABF_NAME,
      this.CUMULD_NAME,
      this.CUMULF_NAME,
      this.GEOM_NAME
    ];
  },
  standardColumns: function() {
    return [
      this.ROAD_NAME,
      this.DIRECTION_NAME,
      this.MUNICIPALITY_NAME,
      this.PRD_NAME,
      this.ABD_NAME,
      this.PRF_NAME,
      this.ABF_NAME,
      this.CUMULD_NAME,
      this.CUMULF_NAME,
      this.GEOM_NAME
    ];
  }
});
