export default Object.freeze({
	replaceColor(inSrcImage, inNewColor, inOldColor, inImageName, callbackSymbolAddedEvent){
		const newRed = parseInt(inNewColor.slice(1, 3), 16);
		const newGreen = parseInt(inNewColor.slice(3, 5), 16);
		const newBlue = parseInt(inNewColor.slice(5, 7), 16);

		const oldRed = parseInt(inOldColor.slice(1, 3), 16);
		const oldGreen = parseInt(inOldColor.slice(3, 5), 16);
		const oldBlue = parseInt(inOldColor.slice(5, 7), 16);
                          
		// Create a new Image object
		const img = new Image();

		// Wait for the image to load
		img.onload = function(){
		// Create a canvas element
			const canvas = document.createElement("canvas");
			canvas.width = img.width;
			canvas.height = img.height;

			// Get the canvas context
			const ctx = canvas.getContext("2d");

			// Draw the image onto the canvas
			ctx.drawImage(img, 0, 0);

			// Get the image data from the canvas
			const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

			// Loop through the pixels and replace the color
			for(let i = 0; i < imageData.data.length; i += 4){
			// Check if the pixel matches the color to be replaced
				if(imageData.data[i] === oldRed && imageData.data[i + 1] === oldGreen && imageData.data[i + 2] === oldBlue){
				// Replace the color with the new color
					imageData.data[i] = newRed;
					imageData.data[i + 1] = newGreen;
					imageData.data[i + 2] = newBlue;
				}
			}

			// Put the modified image data back onto the canvas
			ctx.putImageData(imageData, 0, 0);

			// Get the data URL of the modified image
			callbackSymbolAddedEvent(canvas.toDataURL(), inImageName);
		};

		img.src = inSrcImage;
	},
	replaceGrayscaleByColor(inSrcImage, inNewColor, inImageName, callbackSymbolAddedEvent){
		const newRed = parseInt(inNewColor.slice(1, 3), 16);
		const newGreen = parseInt(inNewColor.slice(3, 5), 16);
		const newBlue = parseInt(inNewColor.slice(5, 7), 16);
                          
		// Create a new Image object
		const img = new Image();

		// Wait for the image to load
		img.onload = function(){
		// Create a canvas element
			const canvas = document.createElement("canvas");
			canvas.width = img.width;
			canvas.height = img.height;

			// Get the canvas context
			const ctx = canvas.getContext("2d");

			// Draw the image onto the canvas
			ctx.drawImage(img, 0, 0);

			// Get the image data from the canvas
			const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

			// Loop through the pixels and replace the color
			for(let i = 0; i < imageData.data.length; i += 4){
			// Get current colors
				const r = imageData.data[i];
				const g = imageData.data[i + 1];
				const b = imageData.data[i + 2];

				if(r === g && g === b){
    				
					// Map the grayscale value to a new color
					imageData.data[i] = (255 - r) / 255.0 * newRed;       // Red
					imageData.data[i + 1] = (255 - g) / 255.0 * newGreen; // Green
					imageData.data[i + 2] = (255 - b) / 255.0 * newBlue;        // Blue
				}
			}

			// Put the modified image data back onto the canvas
			ctx.putImageData(imageData, 0, 0);

			// Get the data URL of the modified image
			callbackSymbolAddedEvent(canvas.toDataURL(), inImageName);
		};

		img.src = inSrcImage;
	}
});
